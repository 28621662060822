import { SET_CURRENT_ORDER_ACTION, SET_ORDER_DATA_ACTION } from "../actions/OrderDetailsAction";

const initialState = {
    data: "",
    currentData: [],
};

export default function OrderDetailsReducer(state = initialState, action) {
    if (action.type === SET_ORDER_DATA_ACTION) {
        return {
            ...state,
            data: action.payload,
        };
    }
    if (action.type === SET_CURRENT_ORDER_ACTION) {
        return {
            ...state,
            currentData: action.payload,
        };
    }
    return state;
}
