import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteStore, getStores } from "../../services/CannabisService";
import PageTitle from "../layouts/PageTitle";
import AddCanabies from "../modals/AddCanabies";
import EditCanabies from "../modals/EditCanabies";
import Spinner from "../reusable/Spinner";

export default function CanabiesList(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const notifyTopRight = () => {
    toast.success(`✅ Deleted Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [stores, setStores] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  // console.log(startDate, endDate, "date");
  const limit = 10;
  const imgUrl = "https://api.pushyy.app/";
  function handleFetch() {
    setLoader(true);
    getStores(currentPage, limit, search)
      .then((response) => {
        console.log(response, " response");
        setStores(response.data.data.store);
        setLoader(false);
        const total = response.data.data.total;
        //   console.log(total, "total.....")
        setPageCount(Math.ceil(total / limit));

        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }

  function onDelete(id) {
    console.log(id, " id");
    deleteStore(id)
      .then((response) => {
        handleFetch();
        notifyTopRight(response.message);
      })
      .catch((error) => {
        notifyError(error.response.message);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  function saveStoreIdInLocalStorage(id) {
    localStorage.setItem("storeId", id);
  }
  useEffect(() => {
    handleFetch();
  }, [currentPage]);
  useEffect(() => {
    if (search.length > 0) {
      handleFetch();
    } else {
      handleFetch();
    }
  }, [currentPage, search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Cannabis List" motherMenu="Cannabis" />

      <Col>
        <Card>
          <Card.Header className="">
            <div className="row d-flex justify-content-between ">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      // onKeyDown={(e) => {
                      //   console.log(e.key);
                      //   // if (e.key === "Enter") {
                      //     handleFetch();
                      //     setCurrentPage(0);
                      //   // }
                      // }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={handleFetch}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-2">
                <Link
                  variant="primary"
                  type="button"
                  className="btn btn-info px-2 py-2"
                  onClick={() => setAddModal(true)}
                >
                  Add New +
                </Link>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>STORE NAME</strong>
                  </th>
                  <th>
                    <strong>ADDRESS</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th>
                    <strong>ABOUT</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {stores.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <img
                        src={item.storeImage}
                        alt="img"
                        width={80}
                        height={50}
                      />
                    </td>
                    <td>{item.businessName}</td>
                    <td>{item.loc.address}</td>
                    <td>{item.countryCode + " " + item.storePhoneNumber}</td>
                    <td>{item.about}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setStoreId(item._id);
                              setEditModal(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {stores?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"....."}
                marginPagesDisplayed={2}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                onPageChange={(selected) => {
                  setCurrentPage(selected.selected);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
      <AddCanabies
        show={addModal}
        table={handleFetch}
        onHide={() => setAddModal(false)}
      />
      <EditCanabies
        show={editModal}
        id={storeId}
        table={handleFetch}
        onHide={() => setEditModal(false)}
      />
      {loader && <Spinner />}
    </div>
  );
}
