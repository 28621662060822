import { GET_USERS, USER_BLOCK_UNBLOCK, USER_DELETE, USER_ORDER } from "./endPoints/ApiEndPoints";
import instance from "./Instance";

export function getUsers(currentPage, limit, search, startDate, endDate) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: myHeaders,
    }
  );
}
export function deleteUser(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(USER_DELETE + `?id=${id}`, {
    headers: myHeaders,
  });
}

export function blockUnblockUser(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(USER_BLOCK_UNBLOCK + `?userId=${id}`, {test: ""}, {
    headers: myHeaders,
  });
}

export function getUserOrder(currentPage, limit) {
  const data = localStorage.getItem("tokenDetails");
  const userId = localStorage.getItem("userId");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    USER_ORDER +
      `?userId=${userId}`,
    {
      headers: myHeaders,
    }
  );
}

export function saveUserIdInLocalStorage(id) {
  localStorage.setItem("userId", id);
}