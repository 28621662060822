import { POST_SUPPORTFORM } from "./endPoints/ApiEndPoints";
import instance from "./Instance";

export function postSupportFormDetails(
  name,
  email,
  description,
) {
 
    const postData = {
        name,
        email,
        description,
      };
  return instance.post(POST_SUPPORTFORM,postData);
}
