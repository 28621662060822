import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import ExportIcon from "../../images/Group 625979.png";
import smallBanner from "../../images/Rectangle 2610.png";
import Spinner from "../reusable/Spinner";
import ReactPaginate from "react-paginate";
import { getAllPayment } from "../../services/BannerService";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
export default function Payment() {
  const tableRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const limit = 10;
 
  const imgBaseUrl = "https://pushyy-app.s3.amazonaws.com/";
  const headers = [
    { label: "IMAGE", key: "image" },
    { label: "NAME", key: "name" },
    { label: "VOUCHER NO.", key: "voucherId" },
    { label: "DATE", key: "createdAt" },
    { label: "PLAN", key: "plan" },
    { label: "STATUS", key: "status" },
    { label: "TOTAL AMOUNT", key: "amount" },
  ];
  function handleFetch(props) {
    setLoader(true);
    getAllPayment(currentPage, limit, search, startDate, endDate)
      .then((response) => {
        console.log(response, "user data response");
        setPaymentData(response.data.data.payment);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    handleFetch();
  }, [currentPage, endDate]);
  useEffect(() => {
    if (search.length > 0) {
      handleFetch();
    } else {
      handleFetch();
    }
  }, [currentPage, search]);
  return (
    <div>
      <PageTitle activeMenu="Payment" motherMenu="Payment" />
      <Col className="col-12">
        <Card>
          <Card.Header className="">
            <div className="orders-filter">
              <div className="row flex-grow-1">
                <div className="col-4" style={{ flexGrow: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="input-group border bg-white input-group-sm"
                      style={{ borderRadius: "8px" }}
                    >
                      <input
                        style={{
                          paddingBottom: "25px",
                          paddingTop: "25px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                        type="text"
                        name="table_search"
                        className="form-control float-right border-0"
                        placeholder="Search by plan or voucher no."
                     
                        onChange={(e) => setSearch(e.target.value.trimEnd())}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={handleFetch}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pl-1 col-6 d-flex justify-content-center align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="form-group" >
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="Start date:"
                      value={startDate}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => (
                        "this.className=(this.value!=''?'has-value':'')",
                        setStartDate(e.target.value)
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="End date:"
                      value={endDate}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                {/* <DownloadTableExcel
                  filename="Payment table"
                  sheet="payments"
                  currentTableRef={tableRef.current}
                > */}
                <CSVLink
                  filename={"Payment-file.csv"}
                  data={paymentData}
                  headers={headers}
                >
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#3C94E6",
                      borderColor: "#3C94E6",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    Export
                    <img
                      src={ExportIcon}
                      className="pl-2"
                      style={{ width: "24px", height: "15px" }}
                    ></img>
                  </button>
                </CSVLink>
                {/* </DownloadTableExcel> */}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table ref={tableRef}>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>VOUCHER NO.</strong>
                  </th>
                  <th>
                    <strong>DATE</strong>
                  </th>
                  <th>
                    <strong>PLAN</strong>
                  </th>

                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>TOTAL AMOUNT</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentData.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <img src={imgBaseUrl + item.image} width={80} height={50} />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.voucherId}</td>
                    <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{item.plan}</td>
                    {item.status === "pending" ? (
                      <td>
                        <Badge variant="danger light">{item.status}</Badge>
                      </td>
                    ) : (
                      <td>
                        <Badge variant="success light">{item.status}</Badge>
                      </td>
                    )}

                    <td>${item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {paymentData?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"....."}
                marginPagesDisplayed={2}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                onPageChange={(selected) => {
                  setCurrentPage(selected.selected);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
