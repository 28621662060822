import React, { useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
function OrderDetails(props) {
  const order = props.data;
  const currentOrder = props.currentData;
  console.log(props.currentData," current data")
  console.log(order," data")
  if(order == "" ){
    props.history.push("/users-order-listing");
  }

  // useLayoutEffect(()=>{
    
  // },[order])
  if(order == ""){
return null;
  }
  else{
    return (
      <div>
        <div className="page-titles">
          <h4>Order Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="users-order-listing">Orders</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link>Order Details</Link>
            </li>
          </ol>
        </div>
        <div className="card" style={{ width: "50%" }}>
          <div className="card-body">
            <h3>{currentOrder.PurchasedId}</h3>
            <h2>{order.name}</h2>
            <p className="mb-0">{currentOrder.orderDate}</p>
            {currentOrder?.deals.map((item, i)=>(
              <div className="card-details mb-0">
              <p className="mb-0">{item.dealId.title}</p>
              <p className="mb-0 mt-0">$<del>{item.dealId.totalPrice}</del> {item.dealId.discountPrice}</p>
            </div>
            ))}
            
            <p className="card-detail-p mb-0">Valid On all days | For 1 Person</p>
            {/* <p className="card-detail-p mb-0">Quantity: {item.quantity}</p> */}
            <div className="card-bill">
              <p className="mb-0">BILL DETAILS</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Total</p>
              <p>${currentOrder.billDetails.total}</p>
            </div >
            <div className="d-flex justify-content-between">
              <p>Tax and Charges</p>
              <p>${currentOrder.billDetails.tax}</p>
            </div>
            <div className="d-flex justify-content-between card-amount">
              <p>Amount Payable</p>
              <p>${currentOrder.billDetails.amountPayable}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
 
}
const mapStateToProps = (state) => {
  return {
    data: state.orderData.data,
    currentData:state.orderData.currentData,
  };
};
export default connect(mapStateToProps)(OrderDetails);