/// auth service

export const LOGIN = 'admin/auth/login';

export const CHANGE_PASSWORD = 'admin/auth.changePassword';

// export const DASHBOARD = 'admin/auth/getDashboard';

/// user service

export const GET_USERS = 'admin/user/getAll';

export const USER_BLOCK_UNBLOCK = 'admin/user/userAction';

export const USER_DELETE = 'admin/user/delete';

export const USER_ORDER = 'admin/user/orderDetail';

/// banner service

export const GET_BANNERS ='admin/banner/getBanners';

export const DELETE_BANNER ='admin/banner/deleteBanner';

export const BANNER_ACTION ='admin/banner/action';

export const PAYMENT ='admin/banner/payment';

/// vendor service

export const GET_VENDORS ='admin/vendor/getAll';

export const DELETE_VENDOR ='admin/vendor/delete';

export const DISABLE_VENDOR ='admin/vendor/block';

export const VENDOR_DEALS ='admin/vendor/vendorDeals';

export const DELETE_DEAL ='admin/deal/deleteDeal';

export const DEAL_ACTION ='admin/deal/action';

/// cannabis 
export const GET_STORES ='admin/store/getAll';

export const DELETE_STORES ='admin/store/deleteStore';

export const CREATE_STORES ='admin/store/create';

export const GET_ONE_STORES ='admin/store/getStoreDeal';

export const EDIT_STORES ='admin/store/editStore';

///support form
export const POST_SUPPORTFORM ='user/profile/support';