import { BANNER_ACTION, DELETE_BANNER, GET_BANNERS, PAYMENT } from "./endPoints/ApiEndPoints";
import instance from "./Instance";

export function getAllBanners(currentPage,limit,search,startDate,endDate) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_BANNERS + `?page=${currentPage}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
    headers: myHeaders,
  });
}

export function deleteBanner(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_BANNER + `?id=${id}`, {
    headers: myHeaders,
  });
}

export function bannerAction(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(BANNER_ACTION + `?bannerId=${id}`,{test:""} ,{
    headers: myHeaders,
  });
}
export function getAllPayment(currentPage,limit,search,startDate,endDate) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(PAYMENT + `?page=${currentPage}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
    headers: myHeaders,
  });
}

