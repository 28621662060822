import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { DASHBOARD } from "./endPoints/ApiEndPoints";

export function signUp(userName, email, password) {
  //axios call
  console.log(userName, email, password, "inside signup fun");
  const postData = {
    userName,
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`, postData);
}

export function login(email, password) {
  console.log(email, password, "inside login fun");
  const postData = {
    email,
    password,
  };

  return axios.post(`https://api.pushyy.app/admin/auth/login`, postData);
}
export function resetPassord(oldPassword, newPassword) {
  console.log(oldPassword, newPassword, "in api function");
  const postData = {
    oldPassword,
    newPassword,
  };
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(myHeaders, " headers response");
  return axios.put(
    `https://api.pushyy.app/admin/auth/changePassword`,
    postData,
    {
      headers: myHeaders,
    }
  );
}

export function dashboard() {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.get(`https://api.pushyy.app/admin/auth/getDashboard`, {
    headers: myHeaders,
  });
}


export function formatError(errorResponse) {
  switch (errorResponse.error.data.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  localStorage.setItem("tokenDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  // const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   dispatch(logout(history));
  //   return;
  // }

  // tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  // dispatch(loginConfirmedAction(tokenDetails));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
