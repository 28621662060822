import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
//import logo from '../../images/logo-full.png'

import {
  resetPassord,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import { toast, ToastContainer } from "react-toastify";
function ChangePassword({ password, onHide ,props}) {
  //Modal box
  let errorsObj = {oldPassword : "", newPassword: "", confirmPassword: ""};
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  console.log(oldPassword, newPassword, confirmPassword, " on Change data");

  // const dispatch = useDispatch();
  const notifyTopRight = () => {
    toast.success(`✅ Successfully Updated.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  function onChangePassword(e) {
    console.log("ffffffff")
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (oldPassword === "") {
      errorObj.oldPassword = "Old Password is Required";
      error = true;
    }
    if (newPassword === "") {
      errorObj.newPassword = "Password is Required";
      error = true;
    }
    if (confirmPassword === "") {
      errorObj.confirmPassword = "Password is Required";
      error = true;
    }
    if(oldPassword == newPassword && oldPassword !== ""){
      errorObj.newPassword = "Please create new password";
      error = true;
    }
    if(newPassword !== confirmPassword){
      errorObj.confirmPassword = "Password not matched";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    // dispatch(loadingToggleAction(true));

    // dispatch(signupAction(userName,email, password, props.history));
    resetPassord(oldPassword, newPassword)
      .then((response) => {
        console.log(response, "response from api");
        // swal("Good job!", "Successfully Updated", "success");
        notifyTopRight("");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        onHide();
      })
      .catch((error) => {
        console.log(error.response.data.message ,"api error")
        setApiError(error.response.data.message);
        // if (error.response.data.statusCode === 401) {
        //   localStorage.clear("tokenDetails");
        //   props.history.push("/login");
        // }
      });
  }
  return (
    <>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
       <Modal className="modal fade" show={password}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Change Password</h4>
              {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                {apiError && (
                <div role="alert" className="fade alert-dismissible fade show alert alert-danger show">
                  {apiError}
                </div>
              )}
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Old Password</label>
                    <div className="contact-name">
                      <input
                        type="password"
                        className="form-control"
                        autocomplete="off"
                        name="name"
                      
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Enter your old password"
                      />
                      <span className="validation-text"></span>
                      {errors.oldPassword && (
                    <div className="text-danger fs-12">{errors.oldPassword}</div>
                  )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">New Password</label>
                    <div className="contact-name">
                      <input
                        type="password"
                        className="form-control"
                        autocomplete="off"
                        name="department"
                        
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password"
                      />
                      <span className="validation-text"></span>
                      {errors.newPassword && (
                    <div className="text-danger fs-12">{errors.newPassword}</div>
                  )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Confirm New Password
                    </label>
                    <div className="contact-name">
                      <input
                        type="password"
                        className="form-control"
                        autocomplete="off"
                        name="department"
                        
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Re-enter new password"
                      />
                      <span className="validation-text"></span>
                      {errors.confirmPassword && (
                    <div className="text-danger fs-12">{errors.confirmPassword}</div>
                  )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onChangePassword}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
    </>
   
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ChangePassword);
