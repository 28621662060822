import {
  CREATE_STORES,
  DELETE_STORES,
  EDIT_STORES,
  GET_ONE_STORES,
  GET_STORES,
} from "./endPoints/ApiEndPoints";
import instance from "./Instance";

export function getStores(currentPage, limit, search) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_STORES + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}
export function deleteStore(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_STORES + `?storeId=${id}`, {
    headers: myHeaders,
  });
}

export function createStore(
  responseImage,
  storeName,
  address,
  number,
  about,
  phone
) {
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("lng");
  const postData = {
    storeImage: responseImage,
    storeName,
    address,
    storePhoneNumber: number,
    about,
    lat,
    long,
    countryCode: "+" + phone,
  };
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(CREATE_STORES, postData, {
    headers: myHeaders,
  });
}

export function getOneStores(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_ONE_STORES + `?storeId=${id}`, {
    headers: myHeaders,
  });
}
export function editStore(
  responseImage,
  storeName,
  address,
  number,
  about,
  id,
  code
) {
  console.log(code,"phone code")
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("lng");
  const postData = {
    storeId: id,
    storeImage: responseImage,
    storeName,
    address,
    storePhoneNumber: number,
    about,
    lat,
    long,
    countryCode: "+" + code,
  };
  console.log("+" + code, " code in post data");
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(EDIT_STORES, postData, {
    headers: myHeaders,
  });
}
