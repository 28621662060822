import React, { useState, useEffect } from "react";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import ExportIcon from "../../images/Group 625979.png";
import plusIcon from "../../images/Plus-icon.png";
import AddBanner from "../modals/AddBanner";

import Spinner from "../reusable/Spinner";
import {
  bannerAction,
  deleteBanner,
  getAllBanners,
} from "../../services/BannerService";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
export default function Banner(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const imgBaseUrl = "https://pushyy-app.s3.amazonaws.com/";
  const [loader, setLoader] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [postModal, setPostModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const limit = 10;

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function handleFetch() {
    setLoader(true);
    getAllBanners(currentPage, limit, search, startDate, endDate).then(
      (response) => {
        console.log(response, "user data response");
        setBannerData(response.data.data.bannerData);
        setLoader(false);
        const total = response.data.data.total;
        //   console.log(total, "total.....")

        setPageCount(Math.ceil(total / limit));
        //   console.log(limit,"limit")
        //  console.log(Math.ceil(total / limit)," page count")
        console.log(response.data.data, " table data ");
      }
    );
  }
  function bannerDelete(id) {
    console.log(id, "user is");
    deleteBanner(id)
      .then((response) => {
        console.log(response, "api response");
        notifyTopRight(response.data.message);
        handleFetch();
      })
      .catch((error) => {
        console.log(error.response);
        notifyError(error);
        // console.log(error.response.data.message ,"Login error")
        // setApiError(error.response.data.message);
      });
  }
  function onDisable(id) {
    console.log(id, "user is");
    bannerAction(id)
      .then((response) => {
        console.log(response, "api response");
        notifyTopRight(response.data.message);
        handleFetch();
      })
      .catch((error) => {
        console.log(error.response);
        notifyError(error);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage,endDate]);
  useEffect(() => {
    if (search.length > 0) {
      handleFetch();
    } else {
      handleFetch();
    }
  }, [currentPage, search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Banner" motherMenu="Banner" />
      <Col>
        <Card>
          <Card.Header className="">
              <div className="row d-flex justify-content-between ">
                <div className="col-4" style={{ flexGrow: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="input-group border bg-white input-group-sm"
                      style={{ borderRadius: "8px" }}
                    >
                      <input
                        style={{
                          paddingBottom: "25px",
                          paddingTop: "25px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                        type="text"
                        name="table_search"
                        className="form-control float-right border-0"
                        placeholder="Search by plan"
                        // onKeyDown={(e) => {
                        //   console.log(e.key);
                        //   if (e.key === "Enter") {
                        //     handleFetch();
                        //     // setCurrentPage(0);
                        //   }
                        // }}
                        onChange={(e) => setSearch(e.target.value.trimEnd())}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={handleFetch}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pl-1 col-6 d-flex justify-content-center align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="form-group"  style={{ flexGrow: 1}}>
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="Start date:"
                      value={startDate}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => (
                        "this.className=(this.value!=''?'has-value':'')",
                        setStartDate(e.target.value)
                      )}
                    />
                  </div>
                  <div className="form-group"  style={{ flexGrow: 1}}>
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="End date:"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
          </Card.Header>
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th >
                    <strong>STORE NAME</strong>
                  </th>
                  <th >
                    <strong>START DATE</strong>
                  </th>
                  <th>
                    <strong>END DATE</strong>
                  </th>
                  <th>
                    <strong>PLAN</strong>
                  </th>
                  <th>
                    <strong>PRICE</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bannerData.map((item) => (
                  <tr>
                    <td>
                      <img
                        src={imgBaseUrl + item.image}
                        width={80}
                        height={50}
                      />
                    </td>
                    <td>{item?.storeId?.businessName}</td>
                    <td>{moment(item.startDate).format("DD-MM-YYYY")}</td>
                    <td>{moment(item.endDate).format("DD-MM-YYYY")}</td>
                    <td>{item?.plan}</td>
                    <td>$ {item?.amount}</td>
                    <td>
                     
                     {item.status === "activate" ? (
                        <Badge variant="success light">Active</Badge>
                      ) : (
                        <Badge variant="danger light">Disabled</Badge>
                      ) }
                      
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="i-false btn btn-info light sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width={24} height={24} />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            item.isActive &&
                            <>
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onDisable(item._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onDisable(item._id)}>
                                Disable
                              </Dropdown.Item>
                            )}</>
                          }
                         
                         

                          <Dropdown.Item onClick={() => bannerDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {bannerData?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"....."}
                marginPagesDisplayed={2}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                onPageChange={(selected) => {
                  setCurrentPage(selected.selected);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
      {/* <AddBanner show={postModal} onHide={() => setPostModal(false)} /> */}
      {loader && <Spinner />}
    </div>
  );
}
