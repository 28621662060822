import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import ExportIcon from "../../images/Group 625979.png";
import smallBanner from "../../images/Rectangle 2610.png";
import {
  deleteVendor,
  disableVendor,
  getVendors,
  saveVendorIdInLocalStorage,
} from "../../services/VendorService";
import Spinner from "../reusable/Spinner";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import moment from "moment";
export default function Vendors(props) {
  const imgBaseUrl = "https://pushyy-app.s3.amazonaws.com/";
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [loader, setLoader] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const limit = 10;

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function handleFetch() {
    setLoader(true);
    getVendors(currentPage, limit, search, startDate, endDate)
      .then((response) => {
        setLoader(false);
        setVendor(response.data.data);
        console.log(response.data.data)
        const total = response.data.value;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  function onDelete(id) {
    console.log(id, "user id");
    setLoader(true);
    deleteVendor(id)
      .then((response) => {
        setLoader(false);
        handleFetch();
        notifyTopRight(response.data.message);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  function onDisable(id) {
    setLoader(true);
    disableVendor(id)
      .then((response) => {
        setLoader(false);
        notifyTopRight(response.data.data);
        handleFetch();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  function onEnable(id) {
    setLoader(true);
    disableVendor(id)
      .then((response) => {
        handleFetch();
        setLoader(false);
        notifyTopRight(response.data.data);
      })
      .catch((error) => {
        notifyError(error);
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, endDate]);

  useEffect(() => {
    if (search.length > 0) {
      handleFetch();
    } else {
      handleFetch();
    }
  }, [currentPage, search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Vendors" motherMenu="Vendors" />
      <Col>
        <Card>
          <Card.Header className="">
              <div className="row d-flex justify-content-between ">
                <div className="col-4" style={{ flexGrow: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="input-group border bg-white input-group-sm"
                      style={{ borderRadius: "8px" }}
                    >
                      <input
                        style={{
                          paddingBottom: "25px",
                          paddingTop: "25px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                        type="text"
                        name="table_search"
                        className="form-control float-right border-0"
                        placeholder="Search"
                       
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={handleFetch}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pl-1 col-6 col-6 d-flex justify-content-center align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="Start date:"
                      value={startDate}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => (
                        "this.className=(this.value!=''?'has-value':'')",
                        setStartDate(e.target.value)
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="End date:"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            
          </Card.Header>
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th >
                    <strong>VENDOR NAME</strong>
                  </th>
                  <th >
                    <strong>STORE NAME</strong>
                  </th>
                  <th>
                    <strong>CATEGORY</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th>
                    <strong>No. of ACTIVE DEALS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vendor.map((item) => (
                  <tr>
                    <td onClick={() => props.history.push("/vendors-details")} style={{cursor:"pointer"}}>
                      <img
                        src={imgBaseUrl + item.image}
                        width={80}
                        height={50}
                      ></img>
                    </td>
                    <td
                      onClick={() => (
                        saveVendorIdInLocalStorage(item._id),
                        props.history.push("/vendors-details")
                      )}
                      style={{cursor:"pointer"}}
                    >
                      {item.name}
                    </td>
                    <td
                    
                    >
                     {item.storeName}
                    </td>
                    <td
                    
                    >
                      {item.category}
                    </td>
                    <td
                    
                    >
                      {item.email}
                    </td>
                    <td
                    
                    >
                      {item.phoneNumber}
                    </td>
                    <td
                    
                      style={{ textAlign: "center" }}
                    >
                      {item.activeDeals}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="i-false btn btn-info light sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width={24} height={24} />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link
                              to="vendors-details"
                              onClick={() =>
                                saveVendorIdInLocalStorage(item._id)
                              }
                            >
                              View Details
                            </Link>
                          </Dropdown.Item>
                          {item.isBlocked ? (
                            <Dropdown.Item onClick={() => onEnable(item._id)}>
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onDisable(item._id)}>
                              Disable
                            </Dropdown.Item>
                          )}

                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"....."}
                marginPagesDisplayed={2}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                onPageChange={(selected) => {
                  setCurrentPage(selected.selected);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
