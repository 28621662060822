import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { createStore } from "../../services/CannabisService";
import { uploadFile } from "react-s3";
import Places from "./Places";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Spinner from "../reusable/Spinner";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function AddCanabies({ show, onHide, table }) {
  const config = {
    bucketName: "pushyy-app",
    region: "us-west-2",
    accessKeyId: "AKIA2OS2KQJKGLET7ZE5",
    secretAccessKey: "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",
  };
  let responseImage = {};
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [storeName, setStoreName] = useState("");
  const [address, setAddress] = useState("");
  const [selectAddress, setSelectAddress] = useState("");
  const [number, setNumber] = useState("");
  const [about, setAbout] = useState("");
  const [apiError, setApiError] = useState("");
  const [phone, setPhone] = useState("");
  console.log(phone,"c code ");
  let errorsObj = {
    image: "",
    storeName: "",
    selectAddress: "",
    adderess: "",
    number: "",
    about: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const notifyTopRight = () => {
    toast.success(`✅ Created Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  async function onSubmit(e) {
    setLoader(true);
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (image === "") {
      errorObj.image = "Image is Required";
      error = true;
    }
    if (storeName === "") {
      errorObj.storeName = "Store Name is Required";
      error = true;
    }
    if (address === "") {
      errorObj.address = "Adderess is Required";
      error = true;
    }
    if (number === "") {
      errorObj.number = "Phone Number is Required";
      error = true;
    }
    if (number.length > 1 && number.length < 10) {
      errorObj.number = "Please provide '10' digits number";
      error = true;
    }
    if (about === "") {
      errorObj.about = "This Field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    const file = new File([image], new Date().getTime());
    console.log(file, "after file creation");
    if (file.size > 0) {
      responseImage = await uploadFile(file, config);
      console.log(responseImage, "after upload");
    }

    createStore(
      responseImage.location,
      storeName,
      address,
      number,
      about,
      phone
    )
      .then((response) => {
        console.log(response, "vgvfdfhjvhfvhg");
        notifyTopRight("");
        setImage("");
        setStoreName("");
        setAddress("");
        setNumber("");
        setAbout("");
        onHide();
        table();
        setLoader(false);
      })
      .catch((error) => {
        notifyError(error.response.data.data);
        console.log(error.response, "error");
        setApiError(error.response.data.message);
      });
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={show}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Cannabis Details</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Image</label>
                      <div className="contact-name">
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => setImage(e.target.files[0])}
                          multiple
                          style={{ paddingTop: "14px" }}
                        />
                        {errors.image && (
                          <div className="text-danger fs-12">
                            {errors.image}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Store Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          value={storeName}
                          onChange={(e) => setStoreName(e.target.value)}
                          placeholder="Enter name"
                        />
                        {errors.storeName && (
                          <div className="text-danger fs-12">
                            {errors.storeName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Select Address
                      </label>
                      <div className="contact-name">
                        {/* <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          value={selectAddress}
                          onChange={(e) => setSelectAddress(e.target.value)}
                          placeholder="Enter address"
                        /> */}
                        {/* <PlacesAutoComplete/> */}

                        <Places />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Address</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="Enter complete address"
                        />
                        {errors.address && (
                          <div className="text-danger fs-12">
                            {errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name d-flex">
                        <PhoneInput
                          country={"eg"}
                          enableSearch={true}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                        />
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          placeholder="Enter Phone Number"
                        />
                        <span className="validation-text"></span>
                      </div>
                      {errors.number && (
                        <div className="text-danger fs-12">{errors.number}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">About</label>
                      <div className="contact-name">
                        <textarea
                          rows={5}
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                          placeholder="Write something here"
                        />
                        <span className="validation-text"></span>
                        {errors.about && (
                          <div className="text-danger fs-12">
                            {errors.about}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        {loader && <Spinner />}
      </Modal>
    </>
  );
}
