export const SET_ORDER_DATA_ACTION = '[set data action] set data action';

export const SET_CURRENT_ORDER_ACTION = '[set current action] set current action';


export function setOrderDataAction(data) {
    return {
        type: SET_ORDER_DATA_ACTION,
        payload: data,
    };
}

export function setCurrentOrderAction(currentData) {
    return {
        type: SET_CURRENT_ORDER_ACTION,
        payload: currentData,
    };
}