import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import { Tab, Nav } from "react-bootstrap";
import DealIcon from "../../images/deal-icon.png";
import CountUp from "react-countup";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import ExportIcon from "../../images/Group 625979.png";
import smallBanner from "../../images/Rectangle 2610.png";
import {
  dealAction,
  deleteDeal,
  getVendorDetails,
} from "../../services/VendorService";
import ReactPaginate from "react-paginate";
import Spinner from "../reusable/Spinner";
import { toast, ToastContainer } from "react-toastify";
export default function VendorsDetails(props) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [dealSoled, setDealSoled] = useState("");
  const [type, setType] = useState("all");
  console.log(type);
  const imgBaseUrl = "https://pushyy-app.s3.amazonaws.com/";
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function handleFetch() {
    setLoader(true);
    getVendorDetails(currentPage, limit, search, type)
      .then((response) => {
        console.log(response, "response");
        setDealSoled(response.data.data.totalDealsPurchesed);
        {
          type !== "banner"
            ? setData(response.data.data.dealData)
            : setBannerData(response.data.data.bannerData);
        }
        // setData(response.data.data.dealData);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }

  function onDelete(id) {
    console.log(id, "user id");
    setLoader(true);
    deleteDeal(id)
      .then((response) => {
        setLoader(false);
        console.log(response);
        handleFetch();
        notifyTopRight(response.data.message);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  function onDisable(id) {
    setLoader(true);
    dealAction(id)
      .then((response) => {
        setLoader(false);
        notifyTopRight(response.data.message);
        handleFetch();
      })
      .catch((error) => {
        setLoader(false);
        // console.log(error.response.data.message, "hello")
        notifyError(error.response.data.message);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    handleFetch();
  }, [currentPage, type]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-titles">
        <h4>Vendors Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="vendors">Vendors</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Vendors Details</Link>
          </li>
        </ol>
      </div>
      <div className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="widget-stat card">
          <div className="card-body p-4">
            <div className="media ai-icon">
              <span className="mr-3 bgl-primary text-primary">
                {/* <i class="ti-user"></i> */}
                <img src={DealIcon} style={{ width: "60%" }} />
              </span>
              <div className="media-body">
                <h3 className="mb-0 text-black">
                  <span className="counter ml-0">
                    <CountUp start={0} end={dealSoled} duration={5} />
                  </span>
                </h3>
                <p className="mb-0">Total Deal Sold</p>
                {/* <small>4% (30 days)</small> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home" onClick={() => setType("all")}>
                Deal Created
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second" onClick={() => setType("active")}>
                Active Deals
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="third" onClick={() => setType("banner")}>
                Banner
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
              <Col>
                <Card>
                  <Card.Header className="">
                    <div className="orders-filter">
                      <div className="row flex-grow-1">
                        <div className="col-4" style={{ flexGrow: 1 }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              className="input-group border bg-white input-group-sm"
                              style={{ borderRadius: "8px" }}
                            >
                              <input
                                style={{
                                  paddingBottom: "25px",
                                  paddingTop: "25px",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                }}
                                type="text"
                                name="table_search"
                                className="form-control float-right border-0"
                                placeholder="Search"
                                onKeyDown={(e) => {
                                  console.log(e.key);
                                  // if (e.key === "Enter") {
                                  handleFetch();
                                  setCurrentPage(0);
                                  // }
                                }}
                                onChange={(e) =>
                                  setSearch(e.target.value.trimEnd())
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={handleFetch}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <thead style={{ color: "black" }}>
                        <tr>
                          <th>
                            <strong>TITLE</strong>
                          </th>
                          <th className="width80">
                            <strong>VALID FROM</strong>
                          </th>
                          <th>
                            <strong>VALID TILL</strong>
                          </th>
                          <th>
                            <strong>TOTAL PRICE</strong>
                          </th>
                          <th>
                            <strong>DISCOUNTED PRICE</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, i) => (
                          <tr key={i}>
                            <td>{item.title}</td>
                            <td>
                              {moment(item.validFrom).format("DD/MM/YYYY")}
                            </td>
                            <td>{moment(item.validTo).format("DD/MM/YYYY")}</td>
                            <td style={{ textAlign: "center" }}>
                              ${item.totalPrice}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              ${item.discountPrice}
                            </td>
                            {item.status === "deactivate" ? (
                              <td>
                                <Badge variant="danger light">
                                  {item.status}
                                </Badge>
                              </td>
                            ) : (
                              <td>
                                <Badge variant="success light">
                                  {item.status}
                                </Badge>
                              </td>
                            )}

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="info light"
                                  className="i-false btn btn-info light sharp"
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {item.isBlocked ? (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Enable
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Disable
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() => onDelete(item._id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {data?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                    {pageCount > 1 && (
                      <ReactPaginate
                        pageCount={pageCount}
                        forcePage={currentPage}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"....."}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination "}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"page-item active"}
                        onPageChange={(selected) => {
                          setCurrentPage(selected.selected);
                        }}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <Col>
                <Card>
                  <Card.Header className="">
                    <div className="orders-filter">
                      <div className="row flex-grow-1">
                        <div className="col-4" style={{ flexGrow: 1 }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              className="input-group border bg-white input-group-sm"
                              style={{ borderRadius: "8px" }}
                            >
                              <input
                                style={{
                                  paddingBottom: "25px",
                                  paddingTop: "25px",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                }}
                                type="text"
                                name="table_search"
                                className="form-control float-right border-0"
                                placeholder="Search"
                                onKeyDown={(e) => {
                                  console.log(e.key);
                                  if (e.key === "Enter") {
                                    handleFetch();
                                    setCurrentPage(0);
                                  }
                                }}
                                onChange={(e) =>
                                  setSearch(e.target.value.trimEnd())
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={handleFetch}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <thead style={{ color: "black" }}>
                        <tr>
                          <th>
                            <strong>TITLE</strong>
                          </th>
                          <th className="width80">
                            <strong>VALID FROM</strong>
                          </th>
                          <th>
                            <strong>VALID TILL</strong>
                          </th>
                          <th>
                            <strong>TOTAL PRICE</strong>
                          </th>
                          <th>
                            <strong>DISCOUNTED PRICE</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, i) => (
                          <tr key={i}>
                            <td>{item.title}</td>
                            <td>
                              {moment(item.validFrom).format("DD/MM/YYYY")}
                            </td>
                            <td>{moment(item.validTo).format("DD/MM/YYYY")}</td>
                            <td>${item.totalPrice}</td>
                            <td>${item.discountPrice}</td>
                            {item.status === "deactivate" ? (
                              <td>
                                <Badge variant="danger light">
                                  {item.status}
                                </Badge>
                              </td>
                            ) : (
                              <td>
                                <Badge variant="success light">
                                  {item.status}
                                </Badge>
                              </td>
                            )}
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="info light"
                                  className="i-false btn btn-info light sharp"
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {item.isBlocked ? (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Enable
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Disable
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() => onDelete(item._id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {data?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                    {pageCount > 1 && (
                      <ReactPaginate
                        pageCount={pageCount}
                        forcePage={currentPage}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"....."}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination "}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"page-item active"}
                        onPageChange={(selected) => {
                          setCurrentPage(selected.selected);
                        }}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="third">
              <Col>
                <Card>
                  <Card.Header className="">
                    <div className="orders-filter">
                      <div className="row flex-grow-1">
                        <div className="col-4" style={{ flexGrow: 1 }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              className="input-group border bg-white input-group-sm"
                              style={{ borderRadius: "8px" }}
                            >
                              <input
                                style={{
                                  paddingBottom: "25px",
                                  paddingTop: "25px",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                }}
                                type="text"
                                name="table_search"
                                className="form-control float-right border-0"
                                placeholder="Search"
                                onKeyDown={(e) => {
                                  console.log(e.key);
                                  if (e.key === "Enter") {
                                    handleFetch();
                                    setCurrentPage(0);
                                  }
                                }}
                                onChange={(e) =>
                                  setSearch(e.target.value.trimEnd())
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={handleFetch}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <thead style={{ color: "black" }}>
                        <tr>
                          <th>
                            <strong>IMAGE</strong>
                          </th>

                          <th>
                            <strong>START DATE</strong>
                          </th>
                          <th>
                            <strong>END DATE</strong>
                          </th>
                          <th>
                            <strong>PLAN</strong>
                          </th>
                          <th>
                            <strong>PRICE</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          {/* <th>
                            <strong>ACTION</strong>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {bannerData.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <img
                                src={imgBaseUrl + item.image}
                                width={80}
                                height={50}
                              />
                            </td>
                            <td>
                              {moment(item.startDate).format("DD/MM/YYYY")}
                            </td>
                            <td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
                            <td>{item.plan}</td>
                            <td>${item.amount}</td>
                            {item.status === "deactivate" ? (
                              <td>
                                <Badge variant="danger light">Disabled</Badge>
                              </td>
                            ) : (
                              <td>
                                <Badge variant="success light">Active</Badge>
                              </td>
                            )}

                            {/* <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="info light"
                                  className="i-false btn btn-info light sharp"
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {item.isBlocked ? (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Enable
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => onDisable(item._id)}
                                    >
                                      Disable
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() => onDelete(item._id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {bannerData?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                    {pageCount > 1 && (
                      <ReactPaginate
                        pageCount={pageCount}
                        forcePage={currentPage}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"....."}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination "}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"page-item active"}
                        onPageChange={(selected) => {
                          setCurrentPage(selected.selected);
                        }}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
