import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { VariableSizeGrid } from "react-window";
import { editStore, getOneStores } from "../../services/CannabisService";
import Places from "./Places";
import { uploadFile } from "react-s3";
import GetAddressByLatLng from "./GetAddressByLatLng";
import Spinner from "../reusable/Spinner";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function EditCanabies({ show, onHide, props, id, table }) {
  const notifyTopRight = () => {
    toast.success(`✅ Updated Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const config = {
    bucketName: "pushyy-app",
    region: "us-west-2",
    accessKeyId: "AKIA2OS2KQJKGLET7ZE5",
    secretAccessKey: "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",
  };
  let responseImage = {};
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [imageEdit, setImageEdit] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [storeName, setStoreName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [about, setAbout] = useState("");
  const [apiError, setApiError] = useState("");
  const [code, setCode] = useState("");
  console.log(code,"code")
  let errorsObj = {
    image: "",
    storeName: "",
    selectAddress: "",
    adderess: "",
    number: "",
    about: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  function handleFetch() {
    setLoader(true);
    getOneStores(id)
      .then((response) => {
        console.log(response, " response");
        localStorage.setItem("lat", response.data.data.loc.coordinates[1]);
        localStorage.setItem("lng", response.data.data.loc.coordinates[0]);
        // setLat(response.data.data.loc.coordinates[1]);
        // setLong(response.data.data.loc.coordinates[0]);
        setCode(response.data.data.countryCode);
        setImage(response.data.data.storeImage);
        setStoreName(response.data.data.businessName);
        setAddress(response.data.data.loc.address);
        setNumber(response.data.data.storePhoneNumber);
        setAbout(response.data.data.about);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "error");
        // setApiError(error.response.data.message);
        // if (error.response.data.statusCode === 401) {
        //   localStorage.clear("tokenDetails");
        //   props.history.push("/login");
        // }
      });
  }
// console.log(storeName,address,about,number)
  async function onUpdateChanges(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (image === "") {
      errorObj.image = "Image is Required";
      error = true;
    }
    if (storeName === "") {
      errorObj.storeName = "Store Name is Required";
      error = true;
    }
    if (address === "") {
      errorObj.address = "Adderess is Required";
      error = true;
    }
    if (number === "") {
      errorObj.number = "Phone Number is Required";
      error = true;
    }
    if (number.length >1 && number.length < 10) {
      errorObj.number = "Please provide '10' digits number";
      error = true;
    }
    if (about === "") {
      errorObj.about = "This Field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    const file = new File([imageEdit], new Date().getTime());
    console.log(file, "after file creation");
    if (file.size > 0) {
      console.log(file, "file");
      responseImage = await uploadFile(file, config);
      console.log(responseImage, "after upload");
    }
    setLoader(true);
    editStore(
      file.size > 0 ? responseImage.location : image,
      storeName,
      address,
      number,
      about,
      id,
      code
    )
      .then((response) => {
        console.log(response, "vgvfdfhjvhfvhg");
        setLoader(false);
        notifyTopRight("");
        setImage("");
        setStoreName("");
        setAddress("");
        setNumber("");
        setAbout("");
        onHide();
        table();
      })
      .catch((error) => {
        // notifyError(error.response.data.message);
        console.log(error.response, "error");
        setApiError(error.response.data.message);
      });
  }

  useEffect(() => {
    if (id) {
      handleFetch();
    }
  }, [id]);
  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={show}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Cannabis Details</h4>
                {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Image</label>
                      <div className="contact-name d-flex gap-2">
                        <img
                          src={imageEdit ? imageEdit : image}
                          style={{ width: "60px", height: "60px" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          // value={image}
                          onChange={(e) => setImageEdit(e.target.files[0])}
                          multiple
                          style={{ paddingTop: "14px" }}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Store Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={storeName}
                          onChange={(e) => setStoreName(e.target.value)}
                          placeholder="Enter name"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Select Address
                      </label>
                      <div className="contact-name">
                        {/* <Places/> */}
                        <GetAddressByLatLng />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Address</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="Enter complete address"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name d-flex">
                        <PhoneInput
                          country={"eg"}
                          enableSearch={true}
                          value={code}
                          onChange={(e) => setCode(e)}
                        />
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          placeholder="Enter Phone Number"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">About</label>
                      <div className="contact-name">
                        <textarea
                          rows={5}
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                          placeholder="Write something here"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onUpdateChanges}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        {loader && <Spinner />}
      </Modal>
    </>
  );
}
