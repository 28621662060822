import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
export default function GetAddressByLatLng() {
    Geocode.setApiKey("AIzaSyBP8VeiGKaJ9BT1iP5LC329tbNcOIuaDTA");
  const [adderessData, setAddressData] = useState("");
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("lng");
  

//   console.log(lat, long ,"lat long")

  function adderessByLatlng() {
    // console.log(lat, long ,"lat long inside function")
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // console.log(city, state, country);
        setValue(city + ", " + state + ", " + country,false);
        // console.log(address);
        localStorage.setItem("lat", lat);
        localStorage.setItem("lng", long);
        // clearSuggestions();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
   if(lat && long){
    adderessByLatlng();
   }
  }, [lat,long]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    console.log(value, " address");
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        console.log("📍 Coordinates: ", { lat, lng });
        localStorage.setItem("lat", lat);
        localStorage.setItem("lng", lng);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  return (
    <div ref={ref}>
      <input
        className="form-control"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter address"
      />
      {status === "OK" && (
        <ul
          style={{
            border: "1px solid grey",
            borderRadius: "0.75rem",
            padding: "5px",
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
}
