import {
  DEAL_ACTION,
  DELETE_DEAL,
  DELETE_VENDOR,
  DISABLE_VENDOR,
  GET_VENDORS,
  VENDOR_DEALS,
} from "./endPoints/ApiEndPoints";
import instance from "./Instance";

export function getVendors(currentPage, limit, search, startDate, endDate) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_VENDORS +
      `?page=${currentPage}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: myHeaders,
    }
  );
}

export function deleteVendor(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_VENDOR + `?id=${id}`, {
    headers: myHeaders,
  });
}

export function disableVendor(id) {
  const data = localStorage.getItem("tokenDetails");
  // const myHeaders = {
  //   Authorization: `Bearer ${data}`,
  // };
  return instance.put(
    DISABLE_VENDOR + `?id=${id}`,
    { test: "" },
    {
      headers: {
        Authorization: `Bearer ${data}`,
      },
    }
  );
}

export function getVendorDetails(currentPage, limit, search, type) {
  const data = localStorage.getItem("tokenDetails");
  const vendorId = localStorage.getItem("vendorId");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    VENDOR_DEALS +
      `?vendorId=${vendorId}&page=${currentPage}&limit=${limit}&search=${search}&type=${type}`,
    {
      headers: myHeaders,
    }
  );
}

export function deleteDeal(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_DEAL + `?dealId=${id}`, {
    headers: myHeaders,
  });
}

export function dealAction(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(DEAL_ACTION + `?dealId=${id}`,{ test: "" }, {
    headers: myHeaders,
  });
}

export function saveVendorIdInLocalStorage(id) {
  localStorage.setItem("vendorId", id);
}



