import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ExportIcon from "../../images/Group 625979.png";
import { Dropdown, Card, Table, Col } from "react-bootstrap";
import Spinner from "../reusable/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { getUserOrder } from "../../services/UserService";
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { setCurrentOrderAction, setOrderDataAction } from "../../store/actions/OrderDetailsAction";
export default function OrdersListing(props) {
  const dispatch = useDispatch();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  console.log(startDate, endDate, "date");
  const limit = 10;

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function handleFetch() {
    setLoader(true);
    getUserOrder(currentPage, limit, search, startDate, endDate)
      .then((response) => {
        console.log(response.data.data.order, "user data response");
        setData(response.data.data.order);
        dispatch(setOrderDataAction(response.data.data.users));
        setLoader(false);
        const total = response.data.value;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("tokenDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, endDate]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-titles">
        <h4>Order List</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="users">Users</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Orders List</Link>
          </li>
        </ol>
      </div>
      <Col>
        <Card>
          {/* <Card.Header className="">
            <div className="orders-filter">
              <div className="row flex-grow-1">
                <div className="col-4" style={{ flexGrow: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="input-group border bg-white input-group-sm"
                      style={{ borderRadius: "8px" }}
                    >
                      <input
                        style={{
                          paddingBottom: "25px",
                          paddingTop: "25px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                        type="text"
                        name="table_search"
                        className="form-control float-right border-0"
                        placeholder="Search"
                        onKeyDown={(e) => {
                          console.log(e.key);
                          if (e.key === "Enter") {
                            handleFetch();
                            setCurrentPage(0);
                          }
                        }}
                        onChange={(e) => setSearch(e.target.value.trimEnd())}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={handleFetch}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pl-1 col-6 d-flex justify-content-center align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="Start date:"
                      value={startDate}
                      onChange={(e) => (
                        "this.className=(this.value!=''?'has-value':'')",
                        setStartDate(e.target.value)
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control orders-filter-border"
                      type="date"
                      placeholder="End date:"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#3C94E6",
                    borderColor: "#3C94E6",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  Export
                  <img
                    src={ExportIcon}
                    className="pl-2"
                    style={{ width: "26px", height: "18px" }}
                  ></img>
                </button>
              </div>
            </div>
          </Card.Header> */}
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th className="width80">
                    <strong>ORDER ID</strong>
                  </th>
                  <th>
                    <strong>DATE</strong>
                  </th>
                  <th>
                    <strong>QUANTITY</strong>
                  </th>
                  <th>
                    <strong>ORDER AMOUNT</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td
                      onClick={() => (
                        dispatch(setCurrentOrderAction(item)),
                        props.history.push("/users-order-details")
                      )}
                    >
                      {item.PurchasedId}
                    </td>
                    <td
                     onClick={() => (
                      dispatch(setCurrentOrderAction(item)),
                      props.history.push("/users-order-details")
                    )}
                    >
                      {item.orderDate}
                    </td>
                    <td
                      onClick={() => (
                        dispatch(setCurrentOrderAction(item)),
                        props.history.push("/users-order-details")
                      )}
                    >
                      {item.deals[0].quantity}
                    </td>
                    <td
                      onClick={() => (
                        dispatch(setCurrentOrderAction(item)),
                        props.history.push("/users-order-details")
                      )}
                    >
                      $ {item.billDetails.amountPayable}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => (
                              dispatch(setCurrentOrderAction(item)),
                              props.history.push("/users-order-details")
                            )}
                          >
                            View Details
                            {/* <Link to="users-order-details">View Details</Link> */}
                          </Dropdown.Item>
                          {/* <Dropdown.Item>Disable</Dropdown.Item>
                        <Dropdown.Item>Delete</Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {data?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"....."}
                marginPagesDisplayed={2}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                onPageChange={(selected) => {
                  setCurrentPage(selected.selected);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
