import React, { useState } from "react";
import { Card } from "react-bootstrap";
import swal from "sweetalert";
import logo from "../../images/pushyy_logo_n.jpg";
import { postSupportFormDetails } from "../../services/SupportFormService";
export default function SupportForm() {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", name: "", description: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (description === "") {
      errorObj.description = "Name is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    // dispatch(loadingToggleAction(true));
    // dispatch(loginAction(email, password, props.history));
    postSupportFormDetails(name, email, description)
      .then((response) => {
        setEmail("");
        setName("");
        setDescription("");
        console.log(response, "vgvfdfhjvhfvhg");
        swal(response.data.message, { icon: "success" });
      })
      .catch((error) => {
        console.log(error, "error");
        setApiError(error.response.data.message);
        swal(error.response.data.message, { icon: "error" });
      });
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card className="support-img-card ">
        <div className="support-card-img">
          <img className="cards-img-logo" src={logo} />
        </div>
        <form className="support-card-form" onSubmit={onSubmit}>
          <div className="support-input">
            <input
              type="text"
              className="support-input-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              pattern="[a-zA-Z][a-zA-Z0-9\s]*"
            />
            <label className="support-input-label">Name</label>
          </div>
          <div className="support-input">
            <input
              type="email"
              className="support-input-field"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label className="support-input-label">Email</label>
          </div>
          <div className="support-input">
            <input
              type="text"
              className="support-input-field"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              pattern="[a-zA-Z][a-zA-Z0-9\s]*"
            />
            <label className="support-input-label">Description</label>
          </div>

          <div className="support-action">
            <button className="support-action-button" type="submit">
              Send Message
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
}
